import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CurrencyExchangeSharp } from '@mui/icons-material';

interface StandardCardProps {
  id: number;
  info: string;
  descriptor: string;
}

const StandardCard = ({ id, info, descriptor }: StandardCardProps) => {
  return (
    <Card
      style={{
        fontSize: 15,
        fontWeight: 'bold',
        cursor: 'move',
        backgroundColor: 'fff',
      }}
      sx={{
        alignSelf: 'center',
        width: {
          xs: 85,
          sm: 100,
          md: 155,
          lg: 205,
          xl: 220,
        },
        height: {
          xs: 85,
          sm: 100,
          md: 155,
          lg: 205,
          xl: 220,
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
        borderRadius: '15px',
      }}
      elevation={5}
    >
      <CardHeader
        sx={{ paddingBottom: 0 }}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <Typography
            sx={{
              paddingBottom: {
                xs: 0,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2,
              },
            }}
            variant="h6"
            color="text.secondary"
          >
            <CurrencyExchangeSharp
              sx={{
                fontSize: {
                  xs: 6,
                  sm: 12,
                  md: 20,
                  lg: 30,
                  xl: 35,
                },
              }}
            />
          </Typography>
        }
      />
      <CardContent
        sx={{
          paddingBlockStart: 0,
          display: 'flex',
          flexDirection: {
            xs: 'row',
            sm: 'column',
            md: 'column',
            lg: 'column',
            xl: 'column',
          },
          gap: '.5em',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography variant="h4" color="text.primary">
          {descriptor}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {info}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StandardCard;
