import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface apiData {
  baseUrl: string;
  envSpecificBaseUrl: string;
}

let envSpecificBaseUrl = 'https://api.geistly.io/';

if (process.env.NODE_ENV === 'development') {
  envSpecificBaseUrl = 'http://localhost:8080/';
}

console.log(`envSpecificBaseUrl is set to: ${envSpecificBaseUrl}`);
console.log(`Current Environment is set to:  ${process.env.NODE_ENV}`);

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: envSpecificBaseUrl }),
  endpoints: (builder) => ({
    getAllBarChartData: builder.query<void, void>({
      query: () => 'general/barchart',
    }),
    getAllLineChartData: builder.query<void, void>({
      query: () => 'general/linechart',
    }),
    getAllCardData: builder.query<void, void>({
      query: () => 'general/cardData',
    }),
    getGeistlyScoreData: builder.query<void, void>({
      query: () => 'general/geistlyScore',
    }),
  }),
});

export const {
  useGetAllBarChartDataQuery,
  useGetAllLineChartDataQuery,
  useGetAllCardDataQuery,
  useGetGeistlyScoreDataQuery,
} = api;
