import styled from 'styled-components';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';

export const StyledWrapperBox = styled(Box)`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const StyledGrid = styled(Grid)`
  /* border: 1px solid black; */
`;

export const StyledDashboardWrapper = styled(Box)`
  /* border: 1px solid teal; */
  display: flex;
  flex-direction: column;
  gap: 1em;

  min-width: 100%;
  min-height: 90%;
  align-self: center;
  /* border: 1px solid red; */
`;

export const StyledCardContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex: 0.5;

  gap: 2em;
  /* border: 2px solid green; */
`;

export const StyledChartContainer = styled(Box)`
  flex: 1;
  padding: 2em;
  position: relative;
  /* border: 1px solid red; */
`;

export const StyledChartIconContainer = styled(Box)`
  position: absolute;
  top: 0px;
  right: 50px;
  z-index: 6;

  border-radius: 5px;
  /* border: 1px solid green; */
`;
