import { ResponsiveLine } from '@nivo/line';
import { useGetAllLineChartDataQuery } from '../../redux/apiSlice';
import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SpinningLoader from '../loading/spinner';

const LineChart: React.FC = () => {
  const { data, isLoading, isSuccess, isError } = useGetAllLineChartDataQuery();
  // console.log(data);

  if (isLoading) {
    return <SpinningLoader />;
  } else if (isSuccess) {
    return (
      <ResponsiveLine
        data={data}
        theme={{
          legends: { text: { fontSize: '1rem' } },
          axis: {
            ticks: {
              text: {
                fontSize: '1rem',
              },
            },
            legend: {
              text: {
                fontSize: '1.125rem',
              },
            },
          },
        }}
        margin={{ top: 30, right: 130, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Week End',
          legendOffset: 40,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Reachout Count',
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        colors={['hsl(93, 70%, 50%)', '#61d4ff']}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    );
  } else if (isError) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          Sorry, an error occured while loading your chart. We are working on
          fixing this. Please come back later or contact our customer support so
          we can check on this for you.
        </h1>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          Sorry, an unknown error occurred please contact our customer support
          if this does not resolve itself within a reasonable amount of time.
        </h1>
      </Box>
    );
  }
};

export default LineChart;
