import { Paper } from '@mui/material';
import styled from 'styled-components';

export const StyledCommentItemContainer = styled(Paper)`
  padding: 0 0.5em 0 0.5em;
`;

export const StyledFeedbackContributor = styled.h4``;

export const StyledFeedbackComment = styled.p`
  font-style: italic;
`;
