import './App.css';
import GlobalFonts from './fonts/fonts';
import Dashboard from './views/dashboard/Dashboard';
import Integrations from './views/integrations/Integrations';
import { ColorModeContext, useMode } from './styles/Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import MasterDashboardTable from './components/masterDashboardTable/MasterDashboardTable';
import { Route, Routes } from 'react-router-dom';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalFonts />
        <div className="App">
          <main className="content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/admin/*" element={<MasterDashboardTable />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
