import styled from 'styled-components';
import { Box, Paper, Typography } from '@mui/material';

export const StyledDrawer = styled(Box)`
  height: 90vh;
  width: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  gap: 2em;
  /* border: 5px solid gold; */
`;

export const StyledIconContainer = styled(Box)`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  gap: 3em;
  padding: 1em;
  /* border: 1px solid red; */
`;

export const StyledIconBox = styled(Box)`
  /* border: 1px solid red; */
  flex: 1;
`;

export const StyledDrawerContentBox = styled(Box)`
  /* border: 1px solid green; */
  padding: 1em;
  gap: 2em;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledGeistlyScoreContainer = styled(Box)`
  /* border: 1px solid red; */
  display: flex;

  flex-direction: column;
  gap: 0.5em;

  flex: 0.5;
`;

export const StyledFeedbackContainer = styled(Box)`
  /* border: 1px solid purple; */
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const StyledLetterScore = styled.h1`
  /* border: 1px solid red; */
  color: #77d926;
  margin: 0;
  padding: 0;
  font-family: 'Geist Font';
  font-weight: 800;
  letter-spacing: 0;
  width: auto;
  align-self: center;
  white-space: nowrap;
  font-size: 6rem;
  /* text-align: center; */
  line-height: 5rem;
`;

export const StyledNumberScore = styled.h2`
  /* border: 1px solid red; */
  color: #000;

  width: auto;
  align-self: center;
  margin: 0;
  margin-right: 0.5rem;
  /* text-align: center; */
`;
