import { useContext } from 'react';
import React from 'react';
import {
  StyledDrawer,
  StyledIconContainer,
  StyledDrawerContentBox,
  StyledLetterScore,
  StyledGeistlyScoreContainer,
  StyledNumberScore,
  StyledFeedbackContainer,
} from './PermDrawerElements';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import FeedbackBlurb from '../feedbackBlurb/feedbackBlurb';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { useTheme, IconButton, Stack, Divider } from '@mui/material';
import { ColorModeContext, tokens } from '../../styles/Theme';
import Avatar from '@mui/material/Avatar';
import { DarkModeOutlined } from '@mui/icons-material';
import { useGetGeistlyScoreDataQuery } from '../../redux/apiSlice';

const SideNav: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  // console.log(theme.palette.mode);

  const {
    data: scoreData,
    isLoading: scoreIsLoading,
    isSuccess: scoreIsSuccess,
    isError: scoreIsError,
  } = useGetGeistlyScoreDataQuery();

  console.log(scoreData, scoreIsLoading, scoreIsSuccess, scoreIsError);

  return (
    <StyledDrawer sx={{ backgroundColor: colors.primary[1010] }}>
      <StyledIconContainer>
        <IconButton>
          <NotificationsNoneOutlinedIcon
            sx={{ fontSize: 30, color: colors.iconColor[500] }}
          />
        </IconButton>
        <IconButton>
          <Avatar sx={{ color: colors.iconColor[500] }}>A&B </Avatar>
        </IconButton>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <LightModeOutlinedIcon sx={{ fontSize: 30 }} />
          ) : (
            <DarkModeOutlined sx={{ fontSize: 30 }} />
          )}
        </IconButton>
      </StyledIconContainer>

      <StyledDrawerContentBox>
        <StyledGeistlyScoreContainer>
          <StyledLetterScore>A -</StyledLetterScore>
          <StyledNumberScore>92</StyledNumberScore>
        </StyledGeistlyScoreContainer>

        <StyledFeedbackContainer>
          <Stack
            spacing={2}
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
          >
            <FeedbackBlurb
              bgColor={colors.grey[900]}
              name={'Brandon Lee'}
              comment={' - I enjoyed the ambiance!'}
            />
            <FeedbackBlurb
              bgColor={colors.grey[900]}
              name={'Victoria Denny'}
              comment={'The margaritas are great!'}
            />
            <FeedbackBlurb
              bgColor={colors.grey[900]}
              name={'Taylor Buchanan'}
              comment={'The food is alright, but the service is astounding!'}
            />
            <FeedbackBlurb
              bgColor={colors.grey[900]}
              name={'Tyler Denny'}
              comment={
                ' - The drink prices are a little high, but the food is great!'
              }
            />
          </Stack>
        </StyledFeedbackContainer>
      </StyledDrawerContentBox>
    </StyledDrawer>
  );
};

export default SideNav;
