import React from 'react';
import { Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { DataLister } from './DataLister';

const MasterDashboardTable = () => {
  return (
    <div></div>
    // <Admin
    //   basename="/admin"
    //   dataProvider={simpleRestProvider('https://api.geistly.io/general/master')}
    // >
    //   <Resource name="transactions" list={DataLister} />
    // </Admin>
  );
};

export default MasterDashboardTable;
