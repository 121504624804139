import { ResponsiveBar } from '@nivo/bar';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from '../../redux/chartSlice';
import { useGetAllBarChartDataQuery } from '../../redux/apiSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';
import SpinningLoader from '../loading/spinner';

const BarChart: React.FC = () => {
  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetAllBarChartDataQuery();

  if (isLoading) {
    return <SpinningLoader />;
  } else if (isSuccess) {
    return (
      <ResponsiveBar
        data={data}
        theme={{
          legends: { text: { fontSize: '1rem' } },
          axis: {
            ticks: {
              text: {
                fontSize: '1rem',
              },
            },
            legend: {
              text: {
                fontSize: '1.125rem',
              },
            },
          },
        }}
        keys={['Reachouts', 'Responses']}
        indexBy="weekEnd"
        margin={{ top: 30, right: 130, bottom: 50, left: 60 }}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={({ id, data }) => String(data[`${id}Color`])}
        colors={['#61d4ff', '#77d926']}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.5]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 1,
          tickRotation: 0,
          legend: 'Week End',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Reachout Count',
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        labelSkipHeight={10}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Bar Chart"
        barAriaLabel={function (e) {
          return (
            e.id + ': ' + e.formattedValue + ' in Week End: ' + e.indexValue
          );
        }}
      />
    );
  } else if (isError) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          Sorry, an error occured while loading your chart. We are working on
          fixing this. Please come back later or contact our customer support so
          we can check on this for you.
        </h1>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h1>
          Sorry, an unknown error occurred please contact our customer support
          if this does not resolve itself within a reasonable amount of time.
        </h1>
      </Box>
    );
  }
};

export default BarChart;
