import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const SpinningLoader: React.FC = (color) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
      }}
    >
      <CircularProgress size={85} color="inherit" />
    </Box>
  );
};

export default SpinningLoader;
