import React from 'react';

import {
  StyledCommentItemContainer,
  StyledFeedbackContributor,
  StyledFeedbackComment,
} from './feedbackBlurbElements';
import { useTheme } from '@mui/material';
import { ColorModeContext, tokens } from '../../styles/Theme';

interface feebbackBlurbProps {
  name: string;
  comment: string;
  bgColor: string;
}

const FeedbackBlurb = ({ name, comment, bgColor }: feebbackBlurbProps) => {
  return (
    <StyledCommentItemContainer elevation={4} sx={{ backgroundColor: bgColor }}>
      <StyledFeedbackContributor>{name}</StyledFeedbackContributor>
      <StyledFeedbackComment>{comment}</StyledFeedbackComment>
    </StyledCommentItemContainer>
  );
};

export default FeedbackBlurb;
