import React from 'react';
import {
  StyledWrapperBox,
  StyledDashboardWrapper,
  StyledCardContainer,
  StyledGrid,
  StyledChartIconContainer,
  StyledChartContainer,
} from './DashboardElements';
import SideNav from '../../components/sideNav/SideNav';
import StandardCard from '../../components/cards/StandardCard';
import PermanentDrawerRight from '../../components/Drawer/PermDrawer';
import BarChart from '../../components/charts/BarChart';
import LineChart from '../../components/charts/LineChart';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../styles/Theme';
import { CardProvider } from '../../utils/CardProvider';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../redux/store';
import { dashboardActions } from '../../redux/dashboardSlice';
import { motion } from 'framer-motion';
import { useGetAllCardDataQuery } from '../../redux/apiSlice';
import SpinningLoader from '../../components/loading/spinner';

const Dashboard: React.FC = () => {
  const cardProvider = CardProvider;

  const {
    data: cardData,
    isLoading: cardIsLoading,
    isSuccess: cardIsSuccess,
    isError: cardIsError,
  } = useGetAllCardDataQuery();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const activeChart = useSelector(
    (state: RootState) => state.dashboard.activeChart
  );

  const toggleBarChart = () => {
    if (activeChart !== 'bar') {
      dispatch(dashboardActions.changeChart('bar'));
    }
  };

  const toggleLineChart = () => {
    if (activeChart !== 'line') {
      dispatch(dashboardActions.changeChart('line'));
    }
  };

  const conditionallyRenderCards = () => {
    if (cardIsLoading) {
      console.log(
        `inside loading block data:${cardData}, isLoading:${cardIsLoading}, isSuccess:${cardIsSuccess}, isError:${cardIsError}`
      );
      return <SpinningLoader />;
    } else if (cardIsSuccess) {
      console.log(
        `inside success block data: ${cardData}, isLoading:${cardIsLoading}, isSuccess:${cardIsSuccess}, isError:${cardIsError}`
      );

      return cardData.map((cards) => {
        return (
          <StandardCard
            info={cards.cardData}
            descriptor={cards.cardDescription}
            id={cards.id}
          />
        );
      });
    } else if (cardIsError) {
      return <Box>Error.</Box>;
    }
  };

  return (
    <StyledGrid container>
      <StyledGrid item xs={1}>
        <SideNav />
      </StyledGrid>
      <StyledGrid item xs={8.5}>
        <StyledWrapperBox>
          <StyledDashboardWrapper>
            <StyledCardContainer>
              {conditionallyRenderCards()}
            </StyledCardContainer>

            <StyledChartContainer height="35vh">
              <StyledChartIconContainer>
                <IconButton
                  onClick={toggleBarChart}
                  component={motion.div}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <BarChartIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  onClick={toggleLineChart}
                  component={motion.div}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <SsidChartIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </StyledChartIconContainer>
              {activeChart === 'bar' && <BarChart />}
              {activeChart === 'line' && <LineChart />}
            </StyledChartContainer>
          </StyledDashboardWrapper>
        </StyledWrapperBox>
      </StyledGrid>
      <StyledGrid item xs={2.5}>
        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <PermanentDrawerRight />
        </Box>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Dashboard;
